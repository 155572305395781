import React from 'react';
import { Link } from 'react-router-dom';
import wowzaLogo from '../../images/preloader-logo.svg'

const Nav = () => {

  return (
    <nav className="navbar navbar-expand-sm navbar-light pb-3 pb-md-2" id="top-nav">
      <a className="navbar-brand" href="https://www.wowza.com"><img width="50%" className="noll"  src={wowzaLogo} alt="Wowza Media Systems" /></a>
    </nav>
  );
}

export default Nav;
