export const SET_COMPOSITE_LAYOUT='SET_COMPOSITE_LAYOUT';
export const SET_COMPOSITE_SIGNALING_URL='SET_COMPOSITE_SIGNALING_URL';
export const SET_COMPOSITE_APPLICATION_NAME='SET_COMPOSITE_APPLICATION_NAME';
export const SET_COMPOSITE_STREAM_NAME='SET_COMPOSITE_STREAM_NAME';
export const SET_COMPOSITE_STREAM_INFO='SET_COMPOSITE_STREAM_INFO';
export const SET_COMPOSITE_AUDIO_BITRATE='SET_COMPOSITE_AUDIO_BITRATE';
export const SET_COMPOSITE_AUDIO_CODEC='SET_COMPOSITE_AUDIO_CODEC';
export const SET_COMPOSITE_AUDIO_TRACK='SET_COMPOSITE_AUDIO_TRACK';
export const SET_COMPOSITE_AUDIO_TRACK_DEVICEID='SET_COMPOSITE_AUDIO_TRACK_DEVICEID';
export const SET_COMPOSITE_VIDEO_BITRATE='SET_COMPOSITE_VIDEO_BITRATE';
export const SET_COMPOSITE_VIDEO_CODEC='SET_COMPOSITE_VIDEO_CODEC';
export const SET_COMPOSITE_VIDEO_FRAME_SIZE_AND_RATE='SET_COMPOSITE_VIDEO_FRAME_SIZE_AND_RATE';
export const SET_COMPOSITE_VIDEO_TRACK='SET_COMPOSITE_VIDEO_TRACK';
export const SET_COMPOSITE_VIDEO_TRACK1_DEVICEID='SET_COMPOSITE_VIDEO_TRACK1_DEVICEID';
export const SET_COMPOSITE_VIDEO_TRACK2_DEVICEID='SET_COMPOSITE_VIDEO_TRACK2_DEVICEID';
export const SET_COMPOSITE_USER_DATA='SET_COMPOSITE_USER_DATA';
export const SET_COMPOSITE_FLAGS='SET_COMPOSITE_FLAGS';

export const startPublish = () => {
  return {
    type: SET_COMPOSITE_FLAGS,
    publishStart: true
  }
}

export const stopPublish = () => {
  return {
    type: SET_COMPOSITE_FLAGS,
    publishStop: true
  }
}