export const SET_PUBLISH_SIGNALING_URL='SET_PUBLISH_SIGNALING_URL';
export const SET_PUBLISH_APPLICATION_NAME='SET_PUBLISH_APPLICATION_NAME';
export const SET_PUBLISH_STREAM_NAME='SET_PUBLISH_STREAM_NAME';
export const SET_PUBLISH_STREAM_INFO='SET_PUBLISH_STREAM_INFO';
export const SET_PUBLISH_AUDIO_BITRATE='SET_PUBLISH_AUDIO_BITRATE';
export const SET_PUBLISH_AUDIO_CODEC='SET_PUBLISH_AUDIO_CODEC';
export const SET_PUBLISH_AUDIO_TRACK='SET_PUBLISH_AUDIO_TRACK';
export const SET_PUBLISH_AUDIO_TRACK_DEVICEID='SET_PUBLISH_AUDIO_TRACK_DEVICEID';
export const SET_PUBLISH_VIDEO_BITRATE='SET_PUBLISH_VIDEO_BITRATE';
export const SET_PUBLISH_VIDEO_CODEC='SET_PUBLISH_VIDEO_CODEC';
export const SET_PUBLISH_VIDEO_FRAME_SIZE_AND_RATE='SET_PUBLISH_VIDEO_FRAME_SIZE_AND_RATE';
export const SET_PUBLISH_VIDEO_TRACK='SET_PUBLISH_VIDEO_TRACK';
export const SET_PUBLISH_VIDEO_TRACK1_DEVICEID='SET_PUBLISH_VIDEO_TRACK1_DEVICEID';
export const SET_PUBLISH_VIDEO_TRACK2_DEVICEID='SET_PUBLISH_VIDEO_TRACK2_DEVICEID';
export const SET_PUBLISH_USER_DATA='SET_PUBLISH_USER_DATA';
export const SET_PUBLISH_FLAGS='SET_PUBLISH_FLAGS';
export const TOGGLE_VIDEO_ENABLED='TOGGLE_VIDEO_ENABLED';
export const TOGGLE_AUDIO_ENABLED='TOGGLE_AUDIO_ENABLED';

export const startPublish = () => {
  return {
    type: SET_PUBLISH_FLAGS,
    publishStart: true
  }
}

export const stopPublish = () => {
  return {
    type: SET_PUBLISH_FLAGS,
    publishStop: true
  }
}

export const resetPublish = () => {
  return {
    type: SET_PUBLISH_FLAGS,
    publishStart: false,
    publishStarting: false,
    publishStop: false,
    publishStopping: false,
  }
}
